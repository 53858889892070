<template>
    <div>
        <h1>Contract Management</h1>
        <button style="margin: 10px" @click="pause()">PAUSE</button> <br>
        <button style="margin: 10px" @click="unpause()">UNPAUSE</button> <br>
        <button style="margin: 10px" @click="setMax(1)">Set Max 1 Per Wallet</button> <br>
        <button style="margin: 10px" @click="setMax(2)">Set Max 2 Per Wallet</button> <br>
        <button style="margin: 10px" @click="setMax(3)">Set Max 3 Per Wallet</button> <br>
        <div style="color: white">
            Address: {{account}} <br>
            Balance: {{balance}}
        </div>
        <div>
            <input type="text" v-model="formInput.account">
            <button style="margin-left: 15px; margin-bottom: 15px" @click="tokenOfOwnerByIndex()">Check Balance</button>
            <pre style="color: white; padding: 15px;">{{onis}}</pre>
        </div>
        <br>
        <div>
            <input type="text" v-model="formInput.tokenId" placeholder="TOKEN ID">
            <button style="margin-left: 15px; margin-bottom: 15px" @click="ownerOf()">Owner OF</button>
            <pre style="color: white; padding: 15px;">{{ownerAddress}}</pre>
        </div>
        <br>
        <div>
            <input type="text" v-model="formInput.maxOnis" placeholder="Contract Max Allowed Mint">
            <button style="margin-left: 15px; margin-bottom: 15px" @click="setMaxOnis()">Set Max Mintable</button>
        </div>
        <div>
            <input type="text" v-model="formInput.price" placeholder="Contract Max Allowed Mint">
            <button style="margin-left: 15px; margin-bottom: 15px" @click="setPRICE()">Set NFT PRICE</button>
        </div>
    </div>
</template>
<script src="./InternalOnly.js"></script>
